<template>
	<div class="row">
		<div class="col-md-3 p0 pointer" v-for="(element, index) in productsList" :key="index">
			<div class="d-flex justify-content-center container">
				<div class="card p-2 bg-white m-1" style="width: 100%" :class="!element.stock ? 'borderRed' : ''" @click="onSelectProduct(element)">
					<div class="about-product text-center">
						<div>
							<h6 class="mt-1 text-black-50">{{ element.label }}</h6>
							<a-tag color="green">{{ element.stock }} piezas</a-tag>
						</div>
					</div>
					<div class="d-flex justify-content-between total font-weight-bold">
						<span>Precio de venta</span><span>{{ numeral(element.normal_price).format('$0,0.00') }}</span>
					</div>
					<div class="d-flex justify-content-between total font-weight-bold">
						<span>SKU</span><span>{{ element.sku }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'

export default {
	name: 'productsListComponent',
	computed: {
		...mapGetters('products', ['productsList']),
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
		}
	},
	methods: {
		numeral,
		onSelectProduct(product) {
			this.$emit('selectedProduct', product)
		},
	},
}
</script>

<style lang="sass" scoped>
.borderRed
	border-color: red !important
</style>